.loginBg{
  position: absolute;
  width: 100%;
  height: 100%;
  background: #2d3248;
  text-align: center;
  padding-top: 200px;
}
.ehelloLogo{
  margin: 0 auto;
  margin-bottom: 60px;
}
.ehelloLogo,.ehelloLogo img{
  width: 167px;
	height: 67px;
}

/* 默认样式 */
.loginBg :global(.login-form){
  max-width: 300px;
  margin: 0 auto;
}
.loginBg :global(.login-form-button){
  width: 100%;
} 
.loginBg :global(.ant-form label){
  float: right;
  color: #fff;
  margin-top:-20px;
}